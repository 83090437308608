import React, { useEffect, useState } from 'react';

import HomeScripts from './homeScripts';
import MakingMoney from './makingMoney';
import OurQuality from './ourQuality';
import CreditLine from './creditLine';
import { firebaseAnalytics } from '../../utils/helper';
import HomeBlock from '../../components/sharedComponents/HomeBlock';
import Testimonials from '../../components/GlobalComponents/Testimonials';

import {
  deleteAuthToken,
  deleteOTPVerified,
  deletePhone,
  setReferBy,
  setUTMCampaign,
  setUTMSource,
} from '../../utils/localstorage';
import homeBanner from '../../assets/images/cape-animation.webp';
import GiftCard from '../../assets/images/gift-box.webp';
import FlexibleTenure from '../../assets/images/w-flexible.webp';
import CoinHand from '../../assets/images/coin-hand.webp';
import StepBottomPic from '../../assets/images/stepBottom.png';
import newGooglePlay from '../../assets/images/play-store-white-background.webp';
import newAppleStore from '../../assets/images/apple-store-white-background.webp';
import Donet from '../../assets/images/donet.webp';
import ZeroPercent from '../../assets/images/zero-percent.webp';
import './home.scss';
import '../assets/css/color.scss';
import { Buttonred, Buttonredsmall } from '../../components/GlobalComponents/Buttons';

const useScrollHandler = () => {
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    const onScroll = () => {
      const scrollCheck = window.scrollY > 750;
      setScroll(scrollCheck);
    };

    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [scroll, setScroll]);

  return scroll;
};

export default function Home() {
  useEffect(() => {
    firebaseAnalytics('homepage');
    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get('utm_source');
    const utmCampaign = urlParams.get('utm_campaign');
    const referBy = urlParams.get('referred_by');
    setUTMSource(utmSource);
    setUTMCampaign(utmCampaign);
    setReferBy(referBy);
    deleteAuthToken();
    deletePhone();
    deleteOTPVerified();
  }, []);

  const onClickGetYourCard = () => {
    firebaseAnalytics('apply_now');
    window.open('/signup', '_self');
  };

  const onClickExploreBenifit = () => {
    window.location.assign('/signup');
  };

  const ClickDownloadApp = () => {
    const isMobile = {
      Android() {
        return navigator.userAgent.match(/windows|Android/i);
      },
      iOS() {
        return navigator.userAgent.match(/mac|iPhone|iPad|iPod/i);
      },
    };
    if (isMobile.Android()) {
      window.open('https://play.google.com/store/apps/details?id=com.stashfin.android', '_blank');
    }

    if (isMobile.iOS()) {
      window.open('https://apps.apple.com/us/app/stashfin/id1276990457', '_blank');
    }
  };

  useScrollHandler();

  return (
    <>
      <HomeScripts />
      <div className="homeBanner">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="bannerText text-left">
                <h1 data-aos="fade-up" data-aos-duration="500">
                  <span>
                    Stashfin Credit Line
                    <br />
                  </span>
                  Your Superpower to <br /> Get Instant Funds
                </h1>
                <ul data-aos="fade-up" data-aos-duration="1000">
                  <li>
                    Get a credit limit upto <span className="rupeeText">₹</span>
                    5,00,000
                  </li>
                  <li>Get 0% interest for up to 30 days</li>
                  <li>Select from Flexible Tenure Options upto 36 months</li>
                  <li>Pay interest only on the amount utilized</li>
                </ul>

                <Buttonred data-aos="fade-up" data-aos-duration="1500" onClick={onClickGetYourCard}>
                  Apply Now
                </Buttonred>
                <p className="d-none">
                  With Stashfin you can fulfil all your monetary needs with instant personal loans up to ₹ 5,00,000.
                  With attractive features like low interest rates, flexible repayment tenures, and interest payment
                  only on the amount utilized, be worry-free of all your financial needs. Download Stashfin mobile app,
                  apply in a few steps and get money disbursed in minutes.
                </p>
              </div>
            </div>
            <div className="col-md-4" data-aos="flip-left" data-aos-duration="1500">
              <img src={homeBanner} alt="Stashfin Mobile App" />
            </div>
          </div>
        </div>
        <div className="apply-button-container">
          <Buttonredsmall onClick={onClickGetYourCard}>Apply Now</Buttonredsmall>
        </div>
      </div>
      <OurQuality />

      <HomeBlock
        type="type1"
        images={GiftCard}
        buttonText="Get Stashfin App Now"
        onClick={onClickGetYourCard}
        alt={'Stashfin Gift'}
      >
        <h2 data-aos="fade-up" data-aos-duration="500" className="one-percent">
          <span>
            Credit limit upto <br />
            <span className="rupeeText">₹</span>5,00,000
          </span>
        </h2>
        <p className="d-none">
          With the industry highest credit limit, you can be assured of being covered for yourbigger credit needs like
          renovating your home and even the smallest of credit needs like gifting on a birthday.
        </p>
      </HomeBlock>

      <HomeBlock
        type="type2"
        images={ZeroPercent}
        imagesMobile={ZeroPercent}
        buttonText="Get Stashfin App Now"
        onClick={onClickExploreBenifit}
        alt="Low Interest Rate | Stashfin"
      >
        <h2 data-aos="fade-up" data-aos-duration="500">
          <span>
            {' '}
            Get 0% <br />
            interest for <br /> up to 30 days
          </span>
        </h2>
      </HomeBlock>

      <HomeBlock
        type="type1"
        images={FlexibleTenure}
        buttonText="Get Stashfin App Now"
        onClick={onClickExploreBenifit}
        alt="flexibility in loan repayment | Stashfin"
      >
        <h2 data-aos="fade-up" data-aos-duration="500">
          <span>Flexible tenure upto 36 months</span>
        </h2>
        <p className="d-none">
          A higher loan amount comes with a wide range of EMI repayment tenure so that you pay back at a pace you
          decide.
        </p>
      </HomeBlock>
      <HomeBlock
        type="type2"
        images={Donet}
        imagesMobile={Donet}
        buttonText="Get Stashfin App Now"
        onClick={onClickExploreBenifit}
        alt="Low Interest Amount | Stashfin"
      >
        <h2 data-aos="fade-up" data-aos-duration="500">
          <span>
            Pay interest only
            <br /> on the amount
            <br /> money utilized
          </span>
        </h2>
      </HomeBlock>

      <HomeBlock
        type="type1"
        images={CoinHand}
        buttonText="Get Stashfin App Now"
        onClick={onClickGetYourCard}
        alt={'Instant Online Personal Loan | Stashfin'}
      >
        <h2 data-aos="fade-up" data-aos-duration="500">
          <span>Instant funds across needs</span>
        </h2>
        <p className="d-none">
          Stashfin provides your personal loans irrespective of the type of need. Be it hosting a grand party or simply
          buying a gadget when you run out of cash, Stashfin has gotten you all covered.
        </p>
      </HomeBlock>

      {/* <HomeBlock
        type='type2'
        description='Get the flexibility of repayments and pay as per your convenience. '
        images={EMIImage}
        buttonText='Get your Card today'
        onClick={onClickGetYourCard}
      >
        <h2 data-aos='fade-up' data-aos-duration='500'>
          <span>Truly easy</span> EMIs
        </h2>
      </HomeBlock> */}
      <CreditLine />

      <div className="getStashed text-center" data-aos="fade-up" data-aos-duration="800">
        <div className="container">
          <div className="SimplSteps">
            <h2>
              <span>Apply in Easy Steps</span>
            </h2>
            <div className="steps-3">
              <div className="stpes text-center">
                <span className="nmbr" data-aos="zoom-in" data-aos-delay="700">
                  01
                </span>
                <p data-aos="fade-up" data-aos-delay="1000">
                  Download <br /> Stashfin app <br /> and sign in
                </p>
              </div>
              <div className="brdr" data-aos="fade-right" data-aos-delay="1500" data-aos-easing="linear">
                ----------
              </div>
              <div className="stpes text-center">
                <span className="nmbr" data-aos="zoom-in" data-aos-delay="1800">
                  02
                </span>
                <p data-aos="fade-up" data-aos-delay="2100">
                  Apply for the <br /> Credit Line
                </p>
              </div>
              <div className="brdr" data-aos="fade-right" data-aos-delay="2400">
                ----------
              </div>
              <div className="stpes text-center">
                <span className="nmbr" data-aos="zoom-in" data-aos-delay="2700">
                  03
                </span>
                <p data-aos="fade-up" data-aos-delay="3000">
                  Complete your <br /> KYC
                </p>
              </div>
              <div className="brdr" data-aos="fade-right" data-aos-delay="2400">
                ----------
              </div>
              <div className="stpes text-center">
                <span className="nmbr" data-aos="zoom-in" data-aos-delay="2700">
                  04
                </span>
                <p data-aos="fade-up" data-aos-delay="3000">
                  Transfer funds <br /> to your bank
                </p>
              </div>
            </div>
            <div>
              <h3>It’s that simple!</h3>
            </div>
            <div className="stepBottomImg">
              <img src={StepBottomPic} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="blueBg" data-aos="fade-up" data-aos-duration="800">
        <div className="blueRatingBox">
          <div className="container">
            <div className="row d-none d-md-flex">
              {/* <div className='col-md-12 text-center'>
                <div className='starRtng'>
                  <img src={Union} />
                  <div className='rtngTxt'>
                    4.1
                    <br />
                    <span>Active Users</span>
                  </div>
                </div>
              </div> */}
              <div className="col-md-6 col-sm-6 col-6 text-center">
                {/* <div className='starRtng'>
                  <img src={Star} />
                  <div className='rtngTxt'>
                    4.1
                    <br />
                    <span>Rated</span>
                  </div>
                </div> */}
                <a
                  href="https://play.google.com/store/apps/details?id=com.stashfin.android"
                  target="_blank"
                  className="d-inline-block"
                  rel="noreferrer"
                >
                  <div className="downloadAppImg">
                    <img src={newGooglePlay} alt="Google Play Icon" />
                  </div>
                  <button>
                    <span>Get it on Google Play</span>
                  </button>
                </a>
              </div>
              <div className="col-md-6 col-sm-6 col-6 text-center">
                <a
                  href="https://apps.apple.com/us/app/stashfin/id1276990457"
                  target="_blank"
                  className="d-inline-block"
                  rel="noreferrer"
                >
                  <div className="downloadAppImg">
                    <img src={newAppleStore} alt="Apple Icon" />
                  </div>
                  <button>
                    <span>Get it on App Store</span>
                  </button>
                </a>
              </div>
            </div>
            <div className="row mobileDownloadBtn">
              <div className="col-md-12 text-center">
                <button type="button" onClick={ClickDownloadApp}>
                  <span>Download the app</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <Testimonials />
      </div>
      <MakingMoney />
    </>
  );
}
