import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { firebaseAnalytics } from '../../../../utils/helper';
import { productTitleMap } from '../../Components/utils';

import {
  createApplication,
  getInsuranceBanner,
  getInsuranceListing,
  getInsuranceProducts,
  getInsuranceProductsCategory,
  getProductDetails,
  gettingPaymentLinkApi,
  gettingPaymentStatus,
  insuranceFeedbackApi,
  postFeedbackApi,
  postInsuranceStashcashLead,
  requestOtp,
  retryPaymentCall,
  userUpdatedInfoApi,
  verifyOtpApi,
  selectBestOfferApiCall,
} from '../services';
import {
  CUSTOMER_NOT_ELIGIBLE,
  CUSTOMER_NOT_REGISTERED,
  GET_CREATE_APPLICATION,
  GET_CREATE_APPLICATION_SUCCESS,
  GET_INSURANCE_LISTING,
  GET_INSURANCE_LISTING_ERROR,
  GET_INSURANCE_LISTING_SUCCESS,
  GET_INSURANCE_PRODUCTS,
  GET_INSURANCE_PRODUCTS_CATEGORY,
  GET_INSURANCE_PRODUCTS_CATEGORY_ERROR,
  GET_INSURANCE_PRODUCTS_CATEGORY_SUCCESS,
  GET_INSURANCE_PRODUCTS_ERROR,
  GET_INSURANCE_PRODUCTS_SUCCESS,
  GET_OTP,
  GET_OTP_ERROR,
  GET_OTP_SUCCESS,
  GET_PAYMENT_LINK,
  GET_PAYMENT_LINK_ERROR,
  GET_PAYMENT_LINK_SUCCESS,
  GET_PAYMENT_STATUS,
  GET_PAYMENT_STATUS_ERROR,
  GET_PAYMENT_STATUS_FAILED,
  GET_PAYMENT_STATUS_LOADING,
  GET_PAYMENT_STATUS_PENDING,
  GET_PAYMENT_STATUS_SUCCESS,
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_DETAILS_ERROR,
  GET_PRODUCT_DETAILS_SUCCESS,
  INSURANCE_FEEDBACK,
  INSURANCE_FEEDBACK_ERROR,
  INSURANCE_FEEDBACK_SUCCESS,
  INSURANCE_STASHCASH_BANNER,
  INSURANCE_STASHCASH_BANNER_ERROR,
  INSURANCE_STASHCASH_BANNER_SUCCESS,
  INSURANCE_STASHCASH_LEAD,
  INSURANCE_STASHCASH_LEAD_ERROR,
  POST_FEEDBACK,
  POST_FEEDBACK_ERROR,
  POST_FEEDBACK_SUCCESS,
  RETRY_PAYMENT,
  RETRY_PAYMENT_ERROR,
  RETRY_PAYMENT_SUCCESS,
  UPDATE_FIELD_INFO,
  UPDATE_FIELD_INFO_ERROR,
  UPDATE_FIELD_INFO_SUCCESS,
  USER_UPDATED_INFO,
  USER_UPDATED_INFO_ERROR,
  USER_UPDATED_INFO_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_ERROR,
  VERIFY_OTP_SUCCESS,
} from '../constants';

const commonError = 'Something went wrong, please try again later';

function* onGetProductDetails({ payload }) {
  try {
    const response = yield call(getProductDetails, payload);
    if (response.status === 200) {
      yield put({ type: GET_PRODUCT_DETAILS_SUCCESS, payload: response.data.data });
    } else {
      yield put({ type: GET_PRODUCT_DETAILS_ERROR, payload: response.data });
    }
  } catch (err) {
    yield put({ type: GET_PRODUCT_DETAILS_ERROR, payload: commonError });
  }
}

function* onGetOtp({ payload }) {
  try {
    const response = yield call(requestOtp, payload.userDetails);
    if (response.status === 200) {
      yield put({ type: GET_OTP_SUCCESS, payload: response.data.data });
    } else {
      yield put({ type: GET_OTP_ERROR, payload: response.error_message });
    }
  } catch (err) {
    yield put({ type: GET_OTP_ERROR, payload: commonError });
  }
}

function* onVerifyOtp({ payload }) {
  try {
    const response = yield call(verifyOtpApi, payload.otpPaylaod);
    if (response.status === 200) {
      firebaseAnalytics('insurance_action_otp_verify_success');
      if (response.data.data.is_eligible && response.data.data.is_registered) {
        const { access_token = '', device_id = '' } = response.data.data;
        if (access_token) localStorage.setItem('authTokenFromOtp', access_token);
        if (!localStorage.getItem('authToken')) localStorage.setItem('authToken', access_token);
        if (!localStorage.getItem('deviceId')) localStorage.setItem('deviceId', device_id);

        const createApplicationResponse = yield call(createApplication, access_token);
        if (createApplicationResponse.status === 200) {
          const { fields, application_uid } = createApplicationResponse?.data?.data || {};
          const defaultValues = Object.assign({}, ...fields.map((item) => ({ [item.key]: item.initial_value })));
          const payloadDetails = {
            productDetails: response.data.data,
            createApplicationDetails: { fields, defaultValues, application_uid },
          };
          yield put({ type: VERIFY_OTP_SUCCESS, payload: payloadDetails });
        }
      } else if (!response.data.data.is_registered) {
        yield put({ type: CUSTOMER_NOT_REGISTERED, payload: 'not_registered' });
      } else if (!response.data.data.is_eligible) {
        yield put({ type: CUSTOMER_NOT_ELIGIBLE, payload: 'not_eligible' });
      }
    } else {
      firebaseAnalytics('insurance_action_otp_verify_failure');
      yield put({ type: VERIFY_OTP_ERROR, payload: response.error_message });
    }
  } catch (err) {
    firebaseAnalytics('insurance_action_otp_verify_failure');
    yield put({ type: VERIFY_OTP_ERROR, payload: commonError });
  }
}

function* onGettingPaymentLink({ payload }) {
  try {
    const response = yield call(gettingPaymentLinkApi, payload.userData);
    if (response.status === 200) {
      yield put({ type: GET_PAYMENT_LINK_SUCCESS, payload: response.data.data });
      window.location.assign(response.data.data.payment_link);
    } else {
      yield put({ type: GET_PAYMENT_LINK_ERROR, payload: response.error_message });
    }
  } catch (err) {
    yield put({ type: GET_PAYMENT_LINK_ERROR, payload: commonError });
  }
}

function* onGetPaymentStatus() {
  let count = 0;
  let currentStatus = '';
  while (true) {
    try {
      const response = yield call(gettingPaymentStatus);
      if (response.status === 200) {
        if (response.data.data.status === 0 || response.data.data.status === 1) {
          currentStatus = 'loading';
          yield put({ type: GET_PAYMENT_STATUS_LOADING, payload: response.data.data.data });
        } else if (response.data.data.status === 2) {
          currentStatus = 'success';
          yield put({ type: GET_PAYMENT_STATUS_SUCCESS, payload: response.data.data.data });
          break;
        } else {
          currentStatus = 'failed';
          yield put({ type: GET_PAYMENT_STATUS_FAILED, payload: response.data.data.data });
          break;
        }
      } else {
        yield put({ type: GET_PAYMENT_STATUS_ERROR, payload: response.error_message });
      }
      count += 1;
      if (count >= 5) {
        if (currentStatus === 'success' || currentStatus === 'failed') {
          break;
        } else {
          yield put({ type: GET_PAYMENT_STATUS_PENDING });
          break;
        }
      }
    } catch (err) {
      yield put({ type: GET_PAYMENT_STATUS_ERROR, payload: commonError });
      break;
    }

    yield delay(5000); // Delay between each poll
  }
}

function* onGetCreateApplication({ payload, navigate }) {
  const { authToken, productId } = payload;
  try {
    const response = yield call(getProductDetails, productId);
    if (response.status === 200) {
      const createApplicationResponse = yield call(createApplication, authToken);
      if (createApplicationResponse.status === 200) {
        const { fields, application_uid } = createApplicationResponse?.data?.data || {};
        const defaultValues = Object.assign({}, ...fields.map((item) => ({ [item.key]: item.initial_value })));
        const payloadDetails = {
          productDetails: response.data.data,
          createApplicationDetails: { fields, defaultValues, application_uid },
        };
        const selectBestOfferPayload = [
          {
            eventName: 'BUY_INSURANCE',
            quantity: 1,
            itemAmount: response?.data?.data?.product_details?.data?.premium,
            eventSubType: productId ? productId.trim() : '',
          },
        ];
        const selectBestOfferResponse = yield call(selectBestOfferApiCall, { authToken, selectBestOfferPayload });
        if (selectBestOfferResponse.status === 200) {
          payloadDetails.selectBestOfferDetails = selectBestOfferResponse?.data;
        }
        yield put({ type: GET_CREATE_APPLICATION_SUCCESS, payload: payloadDetails });
      } else if (createApplicationResponse?.status === 401) {
        const titleName = productTitleMap[productId]?.toLowerCase() || 'insurance';
        const queryParams = new URLSearchParams({ title: titleName });
        navigate(`/insurance/${productId}?${queryParams.toString()}`);
        // navigate(`/insurance/${productId}`);
      }
    } else {
      yield put({ type: GET_PRODUCT_DETAILS_ERROR, payload: response.error_message });
    }
  } catch (err) {
    yield put({ type: GET_PRODUCT_DETAILS_ERROR, payload: commonError });
  }
}

function* onRetryPayment() {
  try {
    const response = yield call(retryPaymentCall);
    if (response.status === 200) {
      yield put({ type: RETRY_PAYMENT_SUCCESS, payload: response.data.data.payment_link });
      window.location.assign(response.data.data.payment_link);
    } else {
      yield put({ type: RETRY_PAYMENT_ERROR, payload: response.error_message });
    }
  } catch (err) {
    yield put({ type: RETRY_PAYMENT_ERROR, payload: commonError });
  }
}

function* onPostFeedback({ payload, historyControl }) {
  try {
    const response = yield call(postFeedbackApi, payload);
    historyControl(false);
    if (response.status === 200) {
      yield put({ type: POST_FEEDBACK_SUCCESS, payload: response });
    } else {
      yield put({ type: POST_FEEDBACK_ERROR, payload: response });
    }
  } catch (err) {
    yield put({ type: POST_FEEDBACK_ERROR, payload: commonError });
  }
}

function* onInsuranceFeedback({ navigate }) {
  try {
    const response = yield call(insuranceFeedbackApi);
    if (response.status === 200) {
      const { is_feedback_required } = response?.data?.data || {};
      if (!is_feedback_required) {
        navigate(-1);
      }
      const payload = { feedbackStatus: is_feedback_required, feedbackData: response?.data?.data };
      yield put({ type: INSURANCE_FEEDBACK_SUCCESS, payload });
    } else {
      navigate(-1);
      yield put({ type: INSURANCE_FEEDBACK_ERROR, payload: response?.data?.error_message });
    }
  } catch (err) {
    yield put({ type: INSURANCE_FEEDBACK_ERROR, payload: commonError });
  }
}

function* onGetUserUpdatedDetails({ payload }) {
  const { formFieldsData, callback } = payload;
  try {
    const response = yield call(userUpdatedInfoApi, formFieldsData);
    if (response.status === 200) {
      const payloadResponse = {
        premiumAmount: response?.data?.data?.amount,
        currentValue: callback,
      };
      yield put({ type: USER_UPDATED_INFO_SUCCESS, payload: payloadResponse });
    } else {
      yield put({ type: USER_UPDATED_INFO_ERROR, payload: response?.data?.error_message || commonError });
    }
  } catch (err) {
    yield put({ type: USER_UPDATED_INFO_ERROR, payload: commonError });
  }
}

function* onGetFieldUpdatedDetails({ payload }) {
  const { formFieldsData, updatedOptions, updatedKey, currentValue } = payload;
  try {
    const response = yield call(userUpdatedInfoApi, formFieldsData);
    if (response.status === 200) {
      const payloadResponse = {
        premiumAmount: response?.data?.data?.amount,
        updatedOptions,
        updatedKey,
        currentValue,
      };
      yield put({ type: UPDATE_FIELD_INFO_SUCCESS, payload: payloadResponse });
    } else {
      yield put({ type: UPDATE_FIELD_INFO_ERROR, payload: { updatedOptions, updatedKey, currentValue } });
    }
  } catch (err) {
    yield put({ type: UPDATE_FIELD_INFO_ERROR, payload: { updatedOptions, updatedKey, currentValue } });
  }
}

function* onGetInsuranceProducts() {
  try {
    const response = yield call(getInsuranceProducts);
    if (response.status === 200) {
      yield put({ type: GET_INSURANCE_PRODUCTS_SUCCESS, payload: response?.data?.data });
    } else {
      yield put({ type: GET_INSURANCE_PRODUCTS_ERROR, payload: commonError });
    }
  } catch (err) {
    yield put({ type: GET_INSURANCE_PRODUCTS_ERROR, payload: commonError });
  }
}

function* onGetInsuranceProductsCategory({ payload }) {
  try {
    const response = yield call(getInsuranceProductsCategory, payload);
    if (response.status === 200) {
      yield put({ type: GET_INSURANCE_PRODUCTS_CATEGORY_SUCCESS, payload: response?.data?.data });
    } else {
      yield put({ type: GET_INSURANCE_PRODUCTS_CATEGORY_ERROR, payload: commonError });
    }
  } catch (err) {
    yield put({ type: GET_INSURANCE_PRODUCTS_CATEGORY_ERROR, payload: commonError });
  }
}

function* onGetInsuranceListing({ payload }) {
  try {
    const response = yield call(getInsuranceListing, payload);
    if (response.status === 200) {
      yield put({ type: GET_INSURANCE_LISTING_SUCCESS, payload: response?.data?.data });
    } else {
      yield put({ type: GET_INSURANCE_LISTING_ERROR, payload: commonError });
    }
  } catch (err) {
    yield put({ type: GET_INSURANCE_LISTING_ERROR, payload: commonError });
  }
}

function* onGetInsuranceBanner() {
  try {
    const response = yield call(getInsuranceBanner);
    if (response.status === 200) {
      yield put({ type: INSURANCE_STASHCASH_BANNER_SUCCESS, payload: response?.data?.data });
    } else {
      yield put({ type: INSURANCE_STASHCASH_BANNER_ERROR, payload: commonError });
    }
  } catch (err) {
    yield put({ type: INSURANCE_STASHCASH_BANNER_ERROR, payload: commonError });
  }
}

function* onPostInsuranceLead({ payload, navigate }) {
  try {
    const response = yield call(postInsuranceStashcashLead, payload);
    if (response.status === 200) {
      navigate('/insurance-stashcash-greet');
    } else {
      yield put({ type: INSURANCE_STASHCASH_LEAD_ERROR, payload: commonError });
    }
  } catch (err) {
    yield put({ type: INSURANCE_STASHCASH_LEAD_ERROR, payload: commonError });
  }
}

export default function* insuranceSagas() {
  yield takeLatest(GET_INSURANCE_PRODUCTS, onGetInsuranceProducts);
  yield takeLatest(GET_INSURANCE_PRODUCTS_CATEGORY, onGetInsuranceProductsCategory);
  yield takeLatest(GET_INSURANCE_LISTING, onGetInsuranceListing);
  yield takeLatest(INSURANCE_STASHCASH_LEAD, onPostInsuranceLead);
  yield takeLatest(INSURANCE_STASHCASH_BANNER, onGetInsuranceBanner);
  yield takeLatest(GET_PRODUCT_DETAILS, onGetProductDetails);
  yield takeLatest(GET_OTP, onGetOtp);
  yield takeLatest(VERIFY_OTP, onVerifyOtp);
  yield takeLatest(GET_PAYMENT_LINK, onGettingPaymentLink);
  yield takeLatest(USER_UPDATED_INFO, onGetUserUpdatedDetails);
  yield takeLatest(UPDATE_FIELD_INFO, onGetFieldUpdatedDetails);
  yield takeLatest(GET_PAYMENT_STATUS, onGetPaymentStatus);
  yield takeLatest(GET_CREATE_APPLICATION, onGetCreateApplication);
  yield takeLatest(RETRY_PAYMENT, onRetryPayment);
  yield takeLatest(INSURANCE_FEEDBACK, onInsuranceFeedback);
  yield takeLatest(POST_FEEDBACK, onPostFeedback);
}
