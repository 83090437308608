/* eslint-disable complexity */

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { bodyFormData } from 'src/utils/helper';

import {
  KYC_DETAILS_SUBMIT_FORM,
  KYC_DETAILS_SUBMIT_FORM_SUCCESS,
  KYC_DETAILS_SUBMIT_FORM_ERROR,
  KYC_DETAILS_SUCCESS,
  KYC_DETAILS_ERROR,
  KYC_DETAILS,
  OKYC_DETAILS,
  OKYC_DETAILS_SUCCESS,
  OKYC_DETAILS_ERROR,
  USER_DETAILS_SUCCESS,
  USER_DETAILS,
  USER_DETAILS_ERROR,
  GET_CITY_NAME,
  GET_CITY_NAME_SUCCESS,
  GET_CITY_NAME_ERROR,
} from './constants';

import {
  kycDetailVerificationApiCall,
  kycRedirectUrlApiCall,
  okycDetailApiCall,
  getCityNameApiCall,
  rekycUserDetailsApiCall,
} from './services';

const commonError = 'Something went wrong, please try again later';

const stateValue = (state) => state.KYCReducer;

function* submitForm() {
  try {
    const { formData, noChanges } = yield select(stateValue);
    const Apireq = {
      first_name: formData.firstName ? formData.firstName : '',
      last_name: formData.lastName ? formData.lastName : '',
      house_no: formData.houseNo ? formData.houseNo : '',
      address_line_1: formData.addressLine1 ? formData.addressLine1 : '',
      address_line_2: formData.addressLine2 ? formData.addressLine2 : '',
      pin_code: Number(formData.pincode ? formData.pincode : ''),
      city: formData.city ? formData.city : '',
      no_changes: noChanges,
    };
    const response = yield call(kycDetailVerificationApiCall, { payload: Apireq });
    if (response.status === 200) {
      yield put({ type: KYC_DETAILS_SUBMIT_FORM_SUCCESS, payload: response?.data });
    } else if (response.status > 200 && response.status < 500) {
      yield put({
        type: KYC_DETAILS_SUBMIT_FORM_ERROR,
        payload: { error: response?.data?.error_message || commonError },
      });
    } else {
      yield put({ type: KYC_DETAILS_SUBMIT_FORM_ERROR, payload: { error: commonError } });
    }
  } catch (err) {
    yield put({ type: KYC_DETAILS_SUBMIT_FORM_ERROR, payload: { error: commonError } });
  }
}

function* kycDetails() {
  try {
    const response = yield call(kycRedirectUrlApiCall);
    if (response.status === 200) {
      yield put({ type: KYC_DETAILS_SUCCESS, payload: response?.data });
    } else if (response.status > 200 && response.status < 500) {
      yield put({
        type: KYC_DETAILS_ERROR,
        payload: { error: response?.data?.error_message || commonError },
      });
    } else {
      yield put({ type: KYC_DETAILS_ERROR, payload: { error: commonError } });
    }
  } catch (err) {
    yield put({ type: KYC_DETAILS_ERROR, payload: { error: commonError } });
  }
}
function* okycDetails() {
  try {
    const { token, deviceId, versionId, checksumValue, modeValue } = yield select(stateValue);
    const Apireq = {
      mode: modeValue,
      checksum: checksumValue,
    };
    const formdata = bodyFormData(Apireq);
    const response = yield call(okycDetailApiCall, { token, deviceId, versionId, payload: formdata });
    if (response.status === 200) {
      yield put({ type: OKYC_DETAILS_SUCCESS, payload: response?.data });
    } else if (response.status > 200 && response.status < 500) {
      yield put({
        type: OKYC_DETAILS_ERROR,
        payload: { error: response?.data?.error_message || commonError },
      });
    } else {
      yield put({ type: OKYC_DETAILS_ERROR, payload: { error: commonError } });
    }
  } catch (err) {
    yield put({ type: OKYC_DETAILS_ERROR, payload: { error: commonError } });
  }
}

function* kycUserDetails() {
  try {
    const response = yield call(rekycUserDetailsApiCall);
    if (response.status === 200) {
      yield put({ type: USER_DETAILS_SUCCESS, payload: response?.data?.data });
    } else if (response.status > 200 && response.status < 500) {
      yield put({
        type: USER_DETAILS_ERROR,
        payload: { error: response?.data?.error_message || commonError },
      });
    } else {
      yield put({ type: USER_DETAILS_ERROR, payload: { error: commonError } });
    }
  } catch (err) {
    yield put({ type: USER_DETAILS_ERROR, payload: { error: commonError } });
  }
}

function* getCityName() {
  const { pincode } = yield select(stateValue);
  try {
    const response = yield call(getCityNameApiCall, { pincode });
    if (response.status === 200) {
      yield put({ type: GET_CITY_NAME_SUCCESS, payload: response?.data?.data?.city });
    } else if (response.status > 200 && response.status < 500) {
      yield put({
        type: GET_CITY_NAME_ERROR,
        payload: { error: response?.data?.error_message || commonError },
      });
    } else {
      yield put({ type: GET_CITY_NAME_ERROR, payload: { error: commonError } });
    }
  } catch (err) {
    yield put({ type: GET_CITY_NAME_ERROR, payload: { error: commonError } });
  }
}

export default function* KYCSagas() {
  yield takeLatest(KYC_DETAILS_SUBMIT_FORM, submitForm);
  yield takeLatest(KYC_DETAILS, kycDetails);
  yield takeLatest(OKYC_DETAILS, okycDetails);
  yield takeLatest(USER_DETAILS, kycUserDetails);
  yield takeLatest(GET_CITY_NAME, getCityName);
}
