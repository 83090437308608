import axios from 'axios';

const BASE_URL = process.env.REACT_APP_NODE_URL;

export const userConsentStatusApiCall = async () => {
  try {
    const res = await axios.get(`${BASE_URL}growth/wealth/user/profile`, {
      headers: {
        auth_token: localStorage.getItem('auth_token'),
        device_id: localStorage.getItem('device_id'),
      },
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const registerUserToGripApiCall = async (user) => {
  let body = {};
  if (user.first_name !== '' && user.last_name !== '' && user.email !== '') {
    body = {
      gatewayId: 'grip',
      bondType: 'listed',
      consent: 'Y',
      user,
    };
  } else {
    body = {
      gatewayId: 'grip',
      bondType: 'listed',
      consent: 'Y',
    };
  }
  try {
    const res = await axios.post(`${BASE_URL}growth/wealth/user/register`, body, {
      headers: {
        auth_token: localStorage.getItem('auth_token'),
        device_id: localStorage.getItem('device_id'),
      },
    });
    return res;
  } catch (err) {
    return err.response;
  }
};
export const getAllGripBondsApiCall = async () => {
  try {
    const res = await axios.get(`${BASE_URL}growth/wealth/asset/list/listed/grip`, {
      headers: {
        auth_token: localStorage.getItem('auth_token'),
        device_id: localStorage.getItem('device_id'),
      },
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getBondDetailsApiCall = async ({ bondId }) => {
  try {
    const res = await axios.get(`${BASE_URL}growth/wealth/asset/listed/grip/${bondId}`, {
      headers: {
        auth_token: localStorage.getItem('auth_token'),
        device_id: localStorage.getItem('device_id'),
      },
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getRedirectionLinkApiCall = async ({ bondId }) => {
  try {
    const res = await axios.get(`${BASE_URL}growth/wealth/user/asset/redirect/listed/grip/${bondId}`, {
      headers: {
        auth_token: localStorage.getItem('auth_token'),
        device_id: localStorage.getItem('device_id'),
      },
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getOrdersApiCall = async () => {
  try {
    const res = await axios.get(`${BASE_URL}growth/wealth/user/portfolio/listed/grip`, {
      headers: {
        auth_token: localStorage.getItem('auth_token'),
        device_id: localStorage.getItem('device_id'),
      },
    });
    return res;
  } catch (err) {
    return err.response;
  }
};
export const redeemNowApiCall = async ({payload}) => {
  debugger
  try {
    const res = await axios.post(`${BASE_URL}rewards/v1/trackReferral`, {
      "referralCode": payload,
      "product": "wealth",
      "productSubType": "default"
    }, {
      headers: {
        auth_token: localStorage.getItem('auth_token'),
        device_id: localStorage.getItem('device_id'),
      },
    });
    return res;
  } catch (err) {
    return err.response;
  }
};
